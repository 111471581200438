import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MultipleSelector from "../ui/multiple-selector";
import { BaseDialog } from "./dialog"
import { Button } from "@/components/ui/button"
import { getBills, getClients, getContracts } from "@/service/weexpertService";
import { DatePicker } from "../ui/date-picker";
import { CheckIcon } from "@radix-ui/react-icons";
import { v4 as uuidv4 } from 'uuid';
import { format } from "date-fns";


export default function BillFilter(props) {

    const { t } = useTranslation();
    const { currentFilter } = props
    const [open, setOpen] = useState(false)

    const [filters, setFilters] = useState([]);
    const [unprocessedFilters, setUnprocessedFilter] = useState({
        selectedClients: [],
        selectedBillsId: [],
        dateOfCreateFrom: null,
        dateOfCreateTo: null,

    });

    const [clients, setClients] = useState([]);
    const [selectedClients, setSelectedClients] = useState(currentFilter?.selectedClients ?? []);
    const [contracts, setContracts] = useState([]);
    const [selectedBillsId, setSelectedBillsId] = useState(currentFilter?.selectedBillsId ?? []);

    const [grossAmounts, setGrossAmounts] = useState([]);
    const [selectedGrossAmounts, setSelectedGrossAmounts] = useState(currentFilter?.selectedGrossAmounts ?? []);

    const [dateOfCreateFrom, setDateOfCreateFrom] = useState(currentFilter.dateOfCreateFrom ? new Date(currentFilter.dateOfCreateFrom) : null);
    const [dateOfCreateTo, setDateOfCreateTo] = useState(currentFilter.dateOfCreateTo ? new Date(currentFilter.dateOfCreateTo) : null);
    const [dateOfCreatePeriod, setDateOfCreatePeriod] = useState([dateOfCreateFrom, dateOfCreateTo]);

    const [dateOfConclusionFrom, setDateOfConclusionFrom] = useState(currentFilter.dateOfConclusionFrom ? new Date(currentFilter.dateOfConclusionFrom) : null);
    const [dateOfConclusionTo, setDateOfConclusionTo] = useState(currentFilter.dateOfConclusionTo ? new Date(currentFilter.dateOfConclusionTo) : null);
    const [dateOfConclusionPeriod, setDateOfConclusionPeriod] = useState([dateOfConclusionFrom, dateOfConclusionTo]);

    const transformAutocompleteValueToFilter = (valueArray, field) => {
        return {
            field: field,
            value: valueArray.map(e => e.id)
        }
    }

    useEffect(() => {
        getClients(1000, 0)
            .then((result) => {
                setClients(result.data.clients
                    .map(client => {
                        return {
                            label: `${client.firstName} ${client.lastName}`,
                            value: `${client.firstName} ${client.lastName}`,
                            id: client.id
                        }
                    })
                );
            })
        getContracts(1000, 0)
            .then((result) => {
                setContracts(result.data.contracts.map(e => {
                    return {
                        label: e.contractId,
                        value: e.contractId,
                        id: e.id
                    }
                }))
            })
    }, [])

    useEffect(() => {
        setDateOfCreatePeriod([dateOfCreateFrom, dateOfCreateTo])
    }, [dateOfCreateFrom, dateOfCreateTo])

    useEffect(() => {
        setDateOfConclusionPeriod([dateOfConclusionFrom, dateOfConclusionTo])
    }, [dateOfConclusionFrom, dateOfConclusionTo])

    useEffect(() => {
        const clientsFilter = transformAutocompleteValueToFilter(selectedClients, 'client');
        const billsIdFilter = transformAutocompleteValueToFilter(selectedBillsId, 'contractId');
        const grossAmountFilter = transformAutocompleteValueToFilter(selectedGrossAmounts, 'grossAmount');

        setUnprocessedFilter({
            selectedClients,
            selectedBillsId,
            dateOfCreateFrom,
            dateOfCreateTo,
            dateOfConclusionFrom,
            dateOfConclusionTo,
            selectedGrossAmounts
        })
        setFilters({
            client: clientsFilter,
            contractId: billsIdFilter,
            grossAmount: grossAmountFilter,
            createdAt: {
                field: 'createdAt',
                value: dateOfCreatePeriod.filter(e => e).length == 2 ?
                    dateOfCreatePeriod.filter(e => e).map(e => format(e, 'yyyy-MM-dd')) : []
            },
            conclusionDate: {
                field: 'createdAt',
                value: dateOfConclusionPeriod.filter(e => e).length == 2 ?
                    dateOfConclusionPeriod.filter(e => e).map(e => format(e, 'yyyy-MM-dd')) : []
            },
        });
    }, [
        selectedClients,
        selectedBillsId,
        dateOfCreateFrom,
        dateOfCreateTo,
        dateOfConclusionFrom,
        dateOfConclusionTo,
        selectedGrossAmounts
    ]);

    const isFilterNotEmpty = (filterData) => {
        return (Object.values(filterData).filter(e => e && (e && e.length > 0)).length > 0)
    }

    return (
        <BaseDialog
            dialogTitle={t('filter')}
            buttonText={t('filter')}
            open={open}
            setOpen={setOpen}
            icon={isFilterNotEmpty(currentFilter) ? <CheckIcon /> : null}
            ommitStyles={isFilterNotEmpty(currentFilter)}
        >
            <div className="w-[600px] pt-1 pb-[80px] px-2">
                <MultipleSelector
                    className="min-h-12"
                    placeholder={t("selected")}
                    badgeClassName="bg-emerald-100 text-black"
                    value={selectedClients}
                    label={t("clients")}
                    onChange={(newSelected) => {
                        setSelectedClients(newSelected)
                    }}
                    options={clients}
                />
                <MultipleSelector
                    className="min-h-12"
                    placeholder={t("selected")}
                    badgeClassName="bg-emerald-100 text-black"
                    value={selectedBillsId}
                    label={t("bills:bills_id")}
                    onChange={(newSelected) => {
                        setSelectedBillsId(newSelected)
                    }}
                    options={contracts}
                />
                <MultipleSelector
                    className="min-h-12"
                    placeholder={t("selected")}
                    badgeClassName="bg-emerald-100 text-black"
                    value={selectedGrossAmounts}
                    label={t("bills:gross_amount")}
                    onChange={(newSelected) => {
                        setSelectedGrossAmounts(newSelected)
                    }}
                    options={grossAmounts}
                />
                <div className="flex justify-between pb-4">
                    <div className="w-[48%]">
                        <DatePicker date={dateOfCreateFrom}
                            setDate={(e) => setDateOfCreateFrom(e)}
                            label={t("invoices:date_of_creation_from")}
                        />
                    </div>
                    <div className="w-[48%]">
                        <DatePicker date={dateOfCreateTo}
                            setDate={(e) => setDateOfCreateTo(e)}
                            label={t("invoices:date_of_creation_to")}
                        />
                    </div>

                </div>
                <div className="flex justify-between pb-4">
                    <div className="w-[48%]">
                        <DatePicker date={dateOfConclusionFrom}
                            setDate={(e) => setDateOfConclusionFrom(e)}
                            label={t("bills:conclusion_date_from")}
                        />
                    </div>
                    <div className="w-[48%]">
                        <DatePicker date={dateOfConclusionTo}
                            setDate={(e) => setDateOfConclusionTo(e)}
                            label={t("bills:conclusion_date_to")}
                        />
                    </div>
                </div>
            </div>
            <div className="w-full flex flex-col items-end">
                <div className="w-full flex justify-end">
                    <Button className="mr-4" variant="outline" onClick={() => {
                        props.onFilterChanged([], {})
                        setOpen(false)
                    }}>{t("common:button_clean_filter")}</Button>
                    <Button onClick={() => {
                        props.onFilterChanged(filters, unprocessedFilters);
                        setOpen(false)
                    }}>{t('button_save')}
                    </Button>
                </div>
            </div>
        </BaseDialog>
    );
}
